<template>
  <div
    class="p-5 min-h-screen flex items-start justify-center"
    :style="{ 'background': `url(/img/man-in-background.webp) no-repeat center center fixed`, 'background-size': 'cover' }"
  >
    <slot />
  </div>
</template>

<script setup lang="ts"></script>
